import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

import AbsolutePositioned from '../../elements/absolute-positioned';

const cardColors = {
    r: "#e3361b",
    g: "#4cba30",
    b: "#2182eb",
    y: "#dec20b",
    n: "black",
};

const cardIn = keyframes`
    0% {
        transform: translateY(-120%);
    }


    100% {
        opacity: 1;
        transform: translateY(0%);
    }
`;

const randomDelay = () => Math.random() * 0.2;

const CardContainer = styled.div`
    position: relative;
    flex-shrink: 0;

    transform: translateY(-120%);
    opacity: 0;

    height: 30vh;
    width: 20vh;
    min-width: 100px;

    margin: 0 5px;

    background-color: ${props => cardColors[props.color]};

    box-shadow: 0 0 2px 4px ${props => props.selected ? "white" : "rgba(0, 0, 0, 0.1)"};

    animation: ${cardIn} 0.5s ease-out ${props => props.animateInDelay}s 1 normal forwards;

    &:hover {
        cursor: ${props => props.selectable ? "pointer": "default"};
    }
`;

const CardText = styled.h1`
    color: white;

    user-select: none;
`; 

class Card extends Component {
    render() {
        return (
            <CardContainer
                color={this.props.color} 
                selectable={!!this.props.onSelect}
                selected={this.props.selected}
                onClick={this.props.onSelect}
                animateInDelay={randomDelay()}
            >
                <AbsolutePositioned x={50} y={50}>
                    <CardText>{this.props.text}</CardText>
                </AbsolutePositioned>
            </CardContainer>
        );
    }
}

export default Card;
