import React from 'react';
import styled, { keyframes } from 'styled-components';

import range from '../../utils/numbers';
import colors from '../../data/colors';

const dimensions = {
    x: {
        left: 0,
        mid: 500,
        right: 1000,
    },
    y: {
        top: 0,
        bottom: 1000,
    },
};

const BannerBottom = styled.svg`
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    height: ${props => props.height};
`;

const ChevronPolygon = styled.polygon`
    fill: ${props => props.color || colors.background};

    opacity: ${props => props.opacity};

    animation: ${props => pulseAnimation(props.opacity)} 2s linear ${props => props.delay}s infinite;
`;

const pulseAnimation = (opacity) => 
    keyframes`
        0% {
            opacity: ${opacity}
        }
    
        15% {
            opacity: ${opacity + 0.1}
        }
    
        30% {
            opacity: ${opacity}
        }
    `;

const Chevron = ({top, chevronDepth, opacity, delay}) => {
    const points = [
        [dimensions.x.left, top],
        [dimensions.x.mid, top + chevronDepth],
        [dimensions.x.right, top],
        [dimensions.x.right, dimensions.y.bottom],
        [dimensions.x.left, dimensions.y.bottom],
    ].map(point => point.join(",")).join(" ");

    return <ChevronPolygon points={points} opacity={opacity} delay={delay} />;
};

const ChevronPattern = ({ height, chevronCount }) => {
    const chevronDepth = 400;
    const offset = (dimensions.y.bottom - chevronDepth) / chevronCount;
    const opacityOffset = Math.min(0.1, 1 / chevronCount);

    return (
        <BannerBottom 
            height={height}
            viewBox={`${dimensions.x.left} ${dimensions.y.top} ${dimensions.x.right} ${dimensions.y.bottom}`} 
            preserveAspectRatio="none">

            {range(chevronCount).map(index => (
                <Chevron 
                    top={index * offset} 
                    chevronDepth={chevronDepth} 
                    opacity={opacityOffset * (index + 1)} 
                    delay={1 + (0.2 * index)} />
            ))}
            <Chevron 
                top={dimensions.y.bottom - chevronDepth} 
                chevronDepth={chevronDepth} 
                opacity={1} 
                delay={1} />
        </BannerBottom>
    );
};

export default ChevronPattern;
