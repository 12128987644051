import React, { Component, Fragment } from 'react';
import styled, { keyframes } from 'styled-components';

import Colors from '../../data/colors';
import BlockButton from '../../elements/block-button';

const fastShakeAnimation = keyframes`
    0% {
        outline: 2px solid red;
    }

    25% {
        transform: translateX(10px);
    }

    50% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }

    100% {
        outline: 2px solid red;
        transform: translateX(0px);
    }
`;

const NameInput = styled.input`
    margin: 5px;
    padding: 0.5em;

    width: 30vw;

    font-size: 14pt;
    font-weight: 200;
    text-align: center;

    border: none;

    animation: ${props => props.animate ? fastShakeAnimation : null} 0.5s 1;

    &:focus {
        outline: none;

        box-shadow: inset 0 0 2px 1px rgba(0, 0, 100, 0.2); 
    }
`;

class JoinForm extends Component {
    constructor(props) {
        super(props);

        this.nameIsValid = this.nameIsValid.bind(this);
        this.nameChange = this.nameChange.bind(this);
        this.endAnimation = this.endAnimation.bind(this);
        this.onRequestJoin = this.onRequestJoin.bind(this);

        this.state = {
            name: "",
            animateTextBox: false,
        };
    }

    nameIsValid() {
        return this.state.name !== "";
    }

    nameChange(event) {
        this.setState({
            name: event.target.value,
        });
    }

    endAnimation() {
        this.setState({
            animateTextBox: false,
        });
    }

    onRequestJoin() {
        if (this.nameIsValid()) {
            this.props.onSubmit(this.state.name);
        } else {
            this.setState({
                animateTextBox: true,
            });
        }
    }

    render() {
        const { state, props } = this;

        return (
            <Fragment>
                <NameInput 
                    type="text" 
                    value={state.name} 
                    placeholder="Name" 
                    onChange={this.nameChange} 
                    animate={state.animateTextBox}
                    onAnimationEnd={this.endAnimation} />
                <br />
                <BlockButton
                    backgroundColor={Colors.skyBlue}
                    onClick={this.onRequestJoin} 
                    disabled={this.props.loading}>
                    {props.loading ? "Joining..." : "Join Game"}
                </BlockButton>
            </Fragment>
        );
    }
}

export default JoinForm;
