import React, { Component } from 'react';
import styled from "styled-components";
import { SocketContext } from 'react-socket-io';

import Card from './card';

const CardContainer = styled.div`
    display: flex;
    align-items: center;

    flex: 4;

    margin: 1em;

    overflow-x: scroll;

    &::-webkit-scrollbar {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        height: 1em;

        border-radius: 0.5em;

        background-color: rgba(255, 255, 255, 0.4);
    }
`;

class Hand extends Component {
    constructor(props) {
        super(props);

        this.selectCard = this.selectCard.bind(this);

        this.state = {
            selectedCard: null,
        };
    }

    selectCard(cardIndex) {
        this.setState({
            selectedCard: cardIndex,
        });

        this.props.onCardSelectionChange(this.props.cards[cardIndex]);
    }

    render() {
        return (
            <CardContainer>
                {this.props.cards.map((card, i) => (
                    <Card 
                        key={i} 
                        text={card.text} 
                        color={card.color} 
                        onSelect={() => this.selectCard(i)}
                        selected={i === this.state.selectedCard}
                    />
                ))}
            </CardContainer>
        );
    }
}

Hand.contextType = SocketContext;

export default Hand;
