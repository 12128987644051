import styled from 'styled-components';

const BlockButton = styled.button`
    margin: 5px;
    padding: 0.5em;

    font-size: 14pt;
    font-weight: 600;
    text-transform: uppercase;

    background-color: transparent;
    color: white;

    border: 4px solid white;

    transition: all 0.5s;

    &:hover {
        background-color: white;
        color: ${props => props.backgroundColor};
    
        cursor: pointer;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background-color: white;
        color: ${props => props.backgroundColor};
    }
`;

export default BlockButton;
