import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { Event, SocketContext } from 'react-socket-io';

import Colors from '../../data/colors';

import Toast from '../../components/onu/toast';
import Footer from '../footer/footer';
import PlayerList from '../onu/player-list';
import GameArea from '../onu/game-area';
import JoinForm from '../onu/join-form';

import AbsolutePositioned from '../../elements/absolute-positioned';

const OnuWrapper = styled.div`
    width: 100%;
    height: 100vh;

    background-color: ${Colors.skyBlue};
`;

const GameWrapper = styled.div`
    display: flex;

    align-items: center;

    width: 100%;
    height: 100%;
`;

const Title = styled.h1`
    margin: 20px auto;

    font-size: 72pt;

    color: white;
`;

class Onu extends Component {
    constructor(props) {
        super(props);

        this.requestJoinGame = this.requestJoinGame.bind(this);
        this.showJoinError = this.showJoinError.bind(this);
        this.requestStartGame = this.requestStartGame.bind(this);
        this.joinGame = this.joinGame.bind(this);
        this.startGame = this.startGame.bind(this);
        this.addNewPlayer = this.addNewPlayer.bind(this);
        this.removePlayer = this.removePlayer.bind(this);
        this.removeToast = this.removeToast.bind(this);

        this.state = {
            joining: false,
            joined: false,
            started: false,
            name: "",
            players: [],
            toasts: [],
        };
    }

    componentDidMount() {
        document.title = `Onu`;
    }

    requestJoinGame(name) {
        this.context.emit('join-game:request', { name });

        this.setState({
            joining: true,
        });
    }

    showJoinError({ reason }) {
        console.log(`Join rejected because ${reason}`);

        this.setState({
            joining: false,
            toasts: this.state.toasts.concat(reason),
        });
    }

    requestStartGame() {
        this.context.emit('start-game:request');
    }

    joinGame({ name, otherPlayers }) {
        this.setState({
            joining: false,
            joined: true,
            name,
            players: otherPlayers.concat(name),
        });
    }
    
    startGame() {
        this.setState({
            started: true,
        });
    }

    addNewPlayer({ name }) {
        this.setState({
            players: this.state.players.concat(name),
        });
    }

    removePlayer({ name }) {
        const { players } = this.state;

        this.setState({
            players: players.filter(playerName => playerName !== name),
        });
    }

    removeToast(key) {
        return () => {
            this.setState({
                toasts: this.state.toasts.filter((toast, i) => i !== key),
            });
        };
    }

    render() {
        const state = this.state;

        return (
            <Fragment>
                <OnuWrapper>
                    <Event event='join-game:accept' handler={this.joinGame} />
                    <Event event='join-game:reject' handler={this.showJoinError} />
                    <Event event='start-game:accept' handler={this.startGame} />
                    <Event event='player-joined' handler={this.addNewPlayer} />
                    <Event event='player-left' handler={this.removePlayer} />
                    {state.joined ? (
                        <GameWrapper>
                            <GameArea onRequestStartGame={this.requestStartGame} started={state.started}/>
                            <PlayerList players={state.players} />
                        </GameWrapper>
                    ) : (
                        <AbsolutePositioned x={50} y={45}>
                            <Title>ONU</Title> 
                            <JoinForm onSubmit={this.requestJoinGame} loading={state.joining} />
                        </AbsolutePositioned>
                    )}
                </OnuWrapper>
                <Footer />
                {state.toasts.map((toastText, i) => (
                    <Toast 
                        key={i} 
                        text={`Request rejected: ${toastText}`} 
                        onClose={this.removeToast(i)}
                    />
                ))}
            </Fragment>
        );
    } 
} 

Onu.contextType = SocketContext;

export default Onu;
