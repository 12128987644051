const currentYear = new Date().getFullYear();

const mainPageData = {
    title: "Chris Milner",
    subtitle: "Software Engineer",
    sections: [
        {
            title: "About Me",
            text: "Hey, I'm Chris - a Full Stack Senior Software engineer currently working at **Ocado Technology**. " 
                + "I've been lucky enough to work across a variety of different areas within Ocado, including simulations, web shop, robotic pick, and my current role developing web applications to support our robots.",
        },
        {
            title: "Skills",
            columns: [
                {
                    title: "Languages",
                    items: [
                        "Java",
                        "JavaScript",
                        "Python",
                        "SQL",
                        "HTML & CSS",
                    ],
                },
                {
                    title: "Frameworks",
                    items: [
                        "React",
                        "Spring",
                        "JUnit",
                        "Jest",
                    ],
                },
                {
                    title: "Other",
                    items: [
                        "Git",
                        "IDEs (IntelliJ etc.)",
                    ],
                },
            ],
        },
    ],
    copyrightText: `Copyright © ${currentYear} Chris Milner`,
};

export default mainPageData;
