import React from 'react';
import styled from 'styled-components';

import content from '../../data/content';
import colors from '../../data/colors';

const FooterWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;

    padding: 1em 2em;

    text-align: right;

    background-color: ${colors.sandBrown};
    color: ${colors.text.light};
`;

const Footer = () => {
    return (
        <FooterWrapper>
            {content.copyrightText}
        </FooterWrapper>
    );
};

export default Footer;
