import React, { Fragment } from 'react';
import styled from 'styled-components';

import Columns from './columns';
import Cards from './cards';
import mainPageData from '../../data/content';
import colors from '../../data/colors';
import queries from '../../data/media-queries';
import formatText from '../../utils/text-formatting';

const SectionWrapper = styled.div`
    width: 60%;

    margin: 2em auto;
    padding: 1em;

    @media ${queries.mobile} {
        width: 85%;
    }
`;

const Title = styled.h1`
    font-size: 36pt;
`;

const Separator = styled.div`
    width: 5em;
    height: 0.6em;

    margin: 0.5em auto;

    background-color: ${colors.sandBrown};
`;

const Text = styled.p`
    text-align: justify;

    font-size: 16pt;

    line-height: 2em;

    @media ${queries.mobile} {
        font-size: 11pt;
    }
`;

const PageContent = () => {
    return mainPageData.sections.map(section => (
        <SectionWrapper>
            {section.title && (
                <Fragment>
                    <Title>{section.title}</Title>
                    <Separator />
                </Fragment>
            )}
            {section.text && (
                <Text dangerouslySetInnerHTML={{__html: formatText(section.text)}} />
            )}
            {section.columns && (
                <Columns columns={section.columns} />
            )}
            {section.cards && (
                <Cards cards={section.cards} />
            )}
        </SectionWrapper>
    ));
};

export default PageContent;
