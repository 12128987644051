import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Event } from 'react-socket-io';

import Card from './card';

const PlayAreaContainer = styled.div`
    display: flex;
    flex-direction: column;

    flex: 5;

    margin: 1em;

    height: 100%;

    justify-content: center;
    align-items: center;

    background-color: rgba(255, 255, 255, 0.1);

    overflow: hidden;
`;

const PlayerText = styled.p`
    margin: 0.5em;

    font-size: 16pt;

    color: white;
`;

class PlayArea extends Component {
    constructor(props) {
        super(props);

        this.setCard = this.setCard.bind(this);

        this.state = {
            player: undefined,
            card: undefined,
        };
    }

    setCard({ player, card }) {
        // Set the card to null so the next card will animate in properly
        this.setState({
            card: null,
        });

        this.setState({
            player,
            card,
        });
    }

    render() {
        const { player, card } = this.state;

        return (
            <PlayAreaContainer>
                <Event event='card-played' handler={this.setCard} />
                {card && (
                    <Fragment>
                        <Card text={card.text} color={card.color} />
                        <PlayerText>Played by {player.name}</PlayerText>
                    </Fragment>
                )}
            </PlayAreaContainer>
        );
    }
}

export default PlayArea;
