import React from "react";
import styled from 'styled-components';

import colors from '../../data/colors';

import BannerHeader from "../banner/banner-header";
import PageContent from "../content/page-content";
import Footer from "../footer/footer";

const AppWrapper = styled.div`
    color: ${colors.text.dark};
`;

const Homepage = () => (
    <AppWrapper>
        <BannerHeader />
        <PageContent />
        <Footer />
    </AppWrapper>
);

export default Homepage;
