import React from 'react';
import styled from 'styled-components';

const ListWrapper = styled.div`
    width: 25%;

    margin: 1em;

    background-color: white;

    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.2);
`;

const Title = styled.h1`
    margin: 0.5em auto;

    font-size: 32pt;
    font-weight: 700;
`;

const List = styled.ul`
    padding: 0;

    list-style-type: none;
`;

const PlayerItem = styled.li`
    margin: 1em auto;

    font-size: 16pt;
    font-weight: 400;
`;

const PlayerList = ({ players }) => (
    <ListWrapper>
        <Title>Players</Title>
        <List>
            {players.map((player, i) => (
                <PlayerItem key={"player" + i}>{player}</PlayerItem>
            ))}
        </List>
    </ListWrapper>
);

export default PlayerList;
