import React, { Component } from 'react';
import styled from 'styled-components';
import { SocketContext } from 'react-socket-io/lib/SocketContext';

import Colors from '../../data/colors';

import BlockButton from '../../elements/block-button';

const ControlContainer = styled.div`
    flex: 0.5;
`;

class Controls extends Component {
    constructor(props) {
        super(props);

        this.requestCard = this.requestCard.bind(this);
        this.playCard = this.playCard.bind(this);
    }

    requestCard() {
        this.context.emit('draw-card:request');
    }

    playCard() {
        console.log("playing card");

        const { selectedCard } = this.props;

        this.context.emit('play-card:request', selectedCard);
        this.props.onPlayCard(selectedCard);
    }

    render() {
        return (
            <ControlContainer>
                <BlockButton 
                    backgroundColor={Colors.skyBlue} 
                    onClick={this.playCard}>
                        Play Card
                </BlockButton>
                <BlockButton 
                    backgroundColor={Colors.skyBlue} 
                    onClick={this.requestCard}>
                        Draw Card
                </BlockButton>
            </ControlContainer>
        );
    }
}

Controls.contextType = SocketContext;

export default Controls;
