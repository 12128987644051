import React from 'react';
import styled from 'styled-components';

import ChevronPattern from './chevron-pattern';
import content from '../../data/content';
import queries from '../../data/media-queries';
import colors from '../../data/colors';

const Banner = styled.div`
    position: relative;

    width: 100%;
    height: 110vh;

    background: ${colors.skyBlue};
    background: linear-gradient(180deg, ${colors.skyBlue} 0%, ${colors.sandBrown} 100%);
`;

const TitlesContainer = styled.div`
    position: absolute;
    top: 40%;
    left: 50%;

    width: 80%;

    transform: translate(-50%, -50%);

    color: ${colors.text.light};

    @media ${queries.tablet} {
        width: 90%;
    }
`;

const Title = styled.h1`
    font-size: 82pt;
    font-weight: 700;

    text-transform: uppercase;

    @media ${queries.mobile} {
        font-size: 32pt;
    }
`;

const Subtitle = styled.h2`
    font-size: 24pt;
    font-weight: 300;

    @media ${queries.mobile} {
        font-size: 18pt;
    }
`;

const BannerHeader = () => (
    <Banner>
        <TitlesContainer>
            <Title>{content.title}</Title>
            <Subtitle>{content.subtitle}</Subtitle>
        </TitlesContainer>
        <ChevronPattern height="25vh" chevronCount={3} />
    </Banner>
);

export default BannerHeader;
