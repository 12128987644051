import React from 'react';
import styled from 'styled-components';

import GoArrowSVG from '../../chevrons-right.svg';
import colors from '../../data/colors';
import queries from '../../data/media-queries';


const cardPadding = {
    x: "2em",
    y: "1em",
};

const CardContainer = styled.div`
    display: flex;

    flex-wrap: wrap;

    justify-content: space-between;
`;

const Card = styled.div`
    position: relative;

    margin: 1em;
    padding: ${cardPadding.y} ${cardPadding.x};
    box-sizing: border-box;

    width: 46%;

    text-align: left;

    box-shadow: 0 1px 8px 2px rgba(0,0,0,0.15);

    @media ${queries.tablet} {
        width: 100%;
    }
`;

const CardTitle = styled.h1`
    font-size: 24pt;
`;

const CardDescription = styled.p`
    margin: 0.5em 0 3em 0;
    padding: 0.5em 10% 0.5em 1em;

    font-weight: 300;

    line-height: 1.5em;

    border-left: 0.5em solid ${colors.sandBrown};

    opacity: 0.8;
`;

const GoArrow = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;

    padding: ${cardPadding.y} ${cardPadding.x};

    opacity: 0.4;

    transition: all 0.5s;

    &:hover {
        cursor: pointer;

        transform: translateX(10%);

        opacity: 1;
    }
`;

const Cards = ({ cards }) => (
    <CardContainer>
        {cards.map(card => (
            <Card>
                <CardTitle>{card.title}</CardTitle>
                {card.description
                    && <CardDescription>{card.description}</CardDescription>
                }
                <GoArrow src={GoArrowSVG} />
            </Card>
        ))}
    </CardContainer>
);

export default Cards;
