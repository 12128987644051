import React from 'react';
import styled from 'styled-components';

import colors from '../../data/colors';
import queries from '../../data/media-queries';

const ColumnContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    justify-content: space-around;
`;

const Column = styled.div`
    display: inline-block;

    margin-top: 1.5em;

    min-width: 300px;

    flex: 1;
`;

const VerticalSeparator = styled.div`
    width: 1px;
    height: 15vh;

    margin: 0 1em;

    align-self: center;

    background-color: ${colors.sandBrown};

    @media ${queries.tablet} {
        display: none;
    }
`;

const ColumnTitle = styled.h1`
    font-size: 20pt;
    font-weight: 500;
`;

const ItemList = styled.ul`
    margin: 1em auto;

    text-align: left;

    list-style: none;

    column-count: 2;
`;

const Item = styled.li`
    display: inline-block;
    
    width: 100%;

    padding: 0.5em 0;

    &::before {
        content: "•";

        display: inline-block;

        width: 1em;

        margin-left: -1em;

        color: ${colors.sandBrown};

        font-weight: 900;
        font-size: 18pt;
    }
`;

const Columns = ({ columns }) => (
    <ColumnContainer>
        {columns.map(column => (
            <Column>
                <ColumnTitle>{column.title}</ColumnTitle>
                {column.items && (
                    <ItemList>
                        {column.items.map(item => (
                            <Item>{item}</Item>
                        ))}
                    </ItemList>
                )}
            </Column>
        )).reduce((prev, curr) => [prev, (
            <VerticalSeparator />
        ), curr])}
    </ColumnContainer>
);

export default Columns;
