import styled from 'styled-components';

const AbsolutePositioned = styled.div`
    position: absolute;
    top: ${props => props.y || 50}%;
    left: ${props => props.x || 50}%;
    transform: translate(-50%, -50%);
`;

export default AbsolutePositioned;
