import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Event } from 'react-socket-io';


import Colors from '../../data/colors';

import Controls from '../onu/controls';
import Hand from '../onu/hand';

import AbsolutePositioned from '../../elements/absolute-positioned';
import BlockButton from '../../elements/block-button';
import PlayArea from './play-area';

const GameAreaWrapper = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;

    margin: 1em;

    width: 75%;
    height: 96%;

    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 0 2px 2px rgba(0, 0, 0, 0.05);
`;

class GameArea extends Component {
    constructor(props) {
        super(props);

        this.addCardsToHand = this.addCardsToHand.bind(this);
        this.removeCard = this.removeCard.bind(this);
        this.changeSelectedCard = this.changeSelectedCard.bind(this);

        this.state = {
            cards: [],
            selectedCard: undefined,
        };
    }

    addCardsToHand(cards) {
        console.log(`Received cards: ${cards.map(card => `${card.text} ${card.color}`)}`);

        this.setState({
            cards: this.state.cards.concat(cards),
        });
    }

    removeCard(cardToRemove) {
        const { cards } = this.state;

        this.setState({
            cards: cards.filter(card => card !== cardToRemove),
        });
    }

    changeSelectedCard(newSelectedCard) {
        this.setState({
            selectedCard: newSelectedCard,
        });
    }

    render() {
        return (
            <GameAreaWrapper>
                {this.props.started ? (
                    <Fragment>
                        <Event event='dealt-cards' handler={this.addCardsToHand} />
                        <PlayArea />
                        <Controls selectedCard={this.state.selectedCard} onPlayCard={this.removeCard} />
                        <Hand cards={this.state.cards} onCardSelectionChange={this.changeSelectedCard} />
                    </Fragment>
                ) : (
                    <AbsolutePositioned x={50} y={50}>
                        <BlockButton 
                            backgroundColor={Colors.skyBlue} 
                            onClick={this.props.onRequestStartGame}>
                                Start Game
                        </BlockButton>
                    </AbsolutePositioned>
                )}
            </GameAreaWrapper>
        );
    }
}

export default GameArea;
