import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';

const AnimateIn = keyframes`
    0% {
        transform: translateY(-120%);
    }

    100% {
        transform: translateY(0);
    }
`;

const AnimateOut = keyframes`
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-120%);
    }
`;

const ToastContainer = styled.div`
    position: fixed;
    top: 10px;
    right: 10px;

    width: 20%;
    min-height: 10%;
    min-width: 200px;

    background-color: white;
    box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1);

    animation: ${props => props.closing ? AnimateOut : AnimateIn} 0.5s ease-out;
`;

const ToastContent = styled.p``;

const Closer = styled.p`
    position: absolute;
    top: 5px;
    right: 5px;

    margin: 0;

    &:hover {
        cursor: pointer;
    }
`;

class Toast extends Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);

        this.state = {
            closing: false,
        };
    }

    close() {
        this.setState({
            closing: true,
        });
    }

    render() {
        const { text, onClose } = this.props;

        return (
            <ToastContainer closing={this.state.closing} onAnimationEnd={this.state.closing ? onClose : null}>
                <ToastContent>{text}</ToastContent>
                <Closer onClick={this.close}>X</Closer>
            </ToastContainer>
        );
    }
}

export default Toast;
