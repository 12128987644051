import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Socket } from 'react-socket-io';

import Homepage from './components/pages/homepage';
import Onu from './components/pages/onu';
import './base.css';

const App = () => {
    return (
        <BrowserRouter className="App">
            <Switch>
                <Route path="/onu">
                    <Socket 
                        uri={`${process.env.REACT_APP_ONU_BACKEND_URL}:${process.env.REACT_APP_ONU_BACKEND_PORT}`} 
                        options={{ transports: ['polling', 'websocket'] }}
                    >
                        <Onu />
                    </Socket>
                </Route>
                <Route path="/">
                    <Homepage />
                </Route>
            </Switch>
        </BrowserRouter>
    );
};

export default App;
