const colors = {
    skyBlue: "#1ec6e8",
    sandBrown: "#da9348",
    background: "#fff",
    text: {
        light: "#fff",
        dark: "#000",
    },
};

export default colors;
